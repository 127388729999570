<template lang="html">
  <section class="search-section-s1" id="search-bar">
    <div class="container oversized-container">
      <b-form class="row" @submit="onSubmitSearch">
        <div class="col col-title">
          <div class="bg-color"></div>

          <div class="box">
            <h5>{{ $t("home.filter.what_are_you") }} <strong>{{ $t("home.filter.looking") }}</strong></h5>
          </div>
        </div>

        <div class="col col-space"></div>

        <!-- <div class="col col-filter">
          <b-form-group class="mb-0 custom-f-group-1"
            label="Location">
            <b-form-select v-model="$root.searchForm.location">
              <b-form-select-option :value="null">Any</b-form-select-option>
              <b-form-select-option :value="loc.id" v-for="(loc, loInx) in $root.locationProps" :key="'loInx-'+loInx">{{ loc.name }}</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div> -->

        <div class="col col-filter">
          <b-form-group class="mb-0 custom-f-group-1"
            :label='$t("home.filter.property_type") '>
            <b-form-select v-model="$root.searchForm.propType"  v-if="$i18n.locale == 'es'">
              <b-form-select-option :value="null">{{ $t("home.filter.any_option") }}</b-form-select-option>
              <b-form-select-option :value="prop.value" v-for="(prop, prtInx) in $root.propTypeOpts" :key="'prtInx-'+prtInx">{{ prop.text_es }}</b-form-select-option>
            </b-form-select>
            <b-form-select v-model="$root.searchForm.propType" v-else-if="$i18n.locale == 'en'">
              <b-form-select-option :value="null">{{ $t("home.filter.any_option") }}</b-form-select-option>
              <b-form-select-option :value="prop.value" v-for="(prop, prtInx) in $root.propTypeOpts" :key="'prtInx-'+prtInx">{{ prop.text }}</b-form-select-option>
            </b-form-select>

          </b-form-group>
        </div>

        <div class="col col-keywords">
          <div class="w-border">
            <b-form-group class="mb-0 custom-f-group-1 input-text"
              :label="$t('home.filter.search_by')">
              <b-form-input
                v-model="$root.searchForm.keywords"
                type="text"
                :placeholder="$t('home.filter.keywords')"

              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="col col-buy-rent" v-if="$i18n.locale == 'en'" style="max-width: 200px !important;">
          <b-form-group class="mb-0 custom-f-group-radio-1"
            v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              v-model="$root.searchForm.buyRent"
              :options="$root.buyRentOpts"
              :aria-describedby="ariaDescribedby"
              button-variant="outline-primary"
              name="radio-btn-outline"
              buttons
              @change="chageRentBuy"
              ></b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col col-buy-rent" v-if="$i18n.locale == 'es'" style="max-width: 200px !important;">
          <b-form-group class="mb-0 custom-f-group-radio-1"
            v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              v-model="$root.searchForm.buyRent"
              :options="$root.buyRentOptsEs"
              :aria-describedby="ariaDescribedby"
              button-variant="outline-primary"
              name="radio-btn-outline"
              buttons
              @change="chageRentBuy"
            ></b-form-radio-group>
          </b-form-group>
        </div>

        <div class="col col-extra-filters">
          <div class="box-btn-fake" @click="$root.isSearchActive = true;">
            <b-form-group class="mb-0 custom-extra-filters-1"
              label=''>
              <div class="_txt" style="padding: 10px;">
                {{ $t("home.filter.advancedfilters") }}
                <!-- <span v-if="$root.searchForm.buyRent != null " >
                  <b>{{ $t("home.filter.listing_type") }}: </b>
                  <span v-if="$root.searchForm.buyRent == 1">{{ $t("home.filter.for_sale") }}</span>
                  <span v-else-if="$root.searchForm.buyRent == 2">{{ $t("home.filter.for_rent") }}</span>
                  <br>
                </span>

                <span v-if="$root.searchForm.price[0] != null && $root.searchForm.price[1] != null" >
                  <b>{{ $t("home.filter.price") }}: </b>
                    Min: $ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format($root.searchForm.price[0]) }} -
                    Max: $ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format($root.searchForm.price[1]) }}
                    <br>
                </span>
                <span v-if="$root.searchForm.propType != null">
                  <b>{{ $t("home.filter.property_type_filter") }}: </b>
                    {{ $root.searchForm.propType}}
                    <br>
                </span>
                <span v-if="$root.searchForm.lifestyle_name != null">
                  <b>{{ $t("home.filter.lifestyle") }}: </b>
                    {{ $root.searchForm.lifestyle_name}}
                    <br>
                </span> -->
              </div>
            </b-form-group>
          </div>
        </div>

        <div class="col col-button col-btn-sm" style="padding-left: 8px;padding-right: 8px;">
          <b-button type="submit" class="btn-search" id="btn-search"><i class="fal fa-search"></i> <span>{{ $t("home.filter.search_btn") }}</span></b-button>
        </div>

        <!-- <div class="col col-about">
          <b-form-group class="mb-0 custom-f-group-1"
            label="About">
            <div class="box-about">
              <router-link to="">Buying</router-link>
              <span> | </span>
              <router-link to="">Selling</router-link>
            </div>
          </b-form-group>
        </div> -->
      </b-form>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {

    }
  },


  methods: {
    onSubmitSearch(event) {
      event.preventDefault();

      //this.$router.push({ name: 'propsPage', query: { buyrent: this.$root.searchForm.buyRent,location: this.$root.searchForm.location,propType: this.$root.searchForm.propType,keywords: this.$root.searchForm.keywords} });
      this.$router.push({ name: 'propsPage', query: { buyrent: this.$root.searchForm.buyRent,
                                                        location: this.$root.searchForm.location,
                                                        propType: this.$root.searchForm.propType,
                                                        minprice: this.$root.searchForm.price[0],
                                                        maxprice: this.$root.searchForm.price[1],
                                                        
                                                        lifestyle: this.$root.searchForm.lifestyle,
                                                      

                                                        minbedrooms: this.$root.searchForm.bedrooms[0],
                                                        maxbedrooms: this.$root.searchForm.bedrooms[1],

                                                        
                                                        mintoilets: this.$root.searchForm.toilets[0],
                                                        maxtoilets: this.$root.searchForm.toilets[1],
                                                        
                                                        keywords: this.$root.searchForm.keywords,
                                                        mininterior: this.$root.searchForm.interior[0],
                                                        maxmininterior: this.$root.searchForm.interior[1],

                                                        minexterior: this.$root.searchForm.exterior[0],
                                                        maxminexterior: this.$root.searchForm.exterior[1],
                              
                                                      } });
    },
    chageRentBuy(){
      document.getElementById("btn-search").click();
       
    }
  },
  watch:{
    searchForm: {
      handler(val){
          console.log('aaa');
        this.$router.push({ name: 'propsPage', query: { buyrent: this.$root.searchForm.buyRent,location: this.$root.searchForm.location,propType: this.$root.searchForm.propType,keywords: this.$root.searchForm.keywords } });
      },
    }

  },
}
</script>
