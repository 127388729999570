<template lang="html">
  <div id="home-page">

    <section class="container-fluid banner-section">
      <swiper class="swiper-desktop" ref="mySwiper" :options="bannersOption">
        <swiper-slide v-for="(banner, bhdInx) in banners.pc" :key="'bhdInx-'+bhdInx">
          <div class="placed-backg slide-content" v-bind:style="{ backgroundImage: 'url('+banner.imageUrl+')' }">
            <img src="public/images/pages/home/banner.png">

            <a class="t-150 property-link" :href="banner.url"  v-if="banner.url && banner.property_name" target="_blank">
              {{banner.property_name}}
            </a>
          </div>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>

      <swiper class="swiper-mobile" :options="bannersOption">
        <swiper-slide  v-for="(banner, bhmInx) in banners.movil" :key="'bhmInx-'+bhmInx">
          <div class="placed-backg slide-content" v-bind:style="{ backgroundImage: 'url('+banner.imageUrl+')' }">
            <img src="public/images/pages/home/banner-m.png">

            <a class="t-150 property-link" :href="banner.url" v-if="banner.url && banner.property_name" target="_blank">
              {{banner.property_name}}
            </a>
          </div>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>

      <a class="btn-banner" @click="pauseBanners">
        <i class="fal fa-pause-circle" v-if="autoplayBanners"></i>
        <i class="fal fa-play-circle" v-else></i>
      </a>
    </section>

    <section class="search-section-s1">
      <div class="container oversized-container">
        <b-form class="row" @submit="onSubmitSearch">
          <div class="col col-title">
            <div class="bg-color"></div>

            <div class="box">
              <h5>{{ $t("home.filter.what_are_you") }} <strong>{{ $t("home.filter.looking") }}</strong></h5>
            </div>
          </div>

          <div class="col col-space"></div>

          <!-- <div class="col col-filter">
            <b-form-group class="mb-0 custom-f-group-1"
              :label="$t('home.filter.location')">
              <b-form-select v-model="$root.searchForm.location">
                <b-form-select-option :value="null">{{ $t("home.filter.any_option") }}</b-form-select-option>
                <b-form-select-option :value="loc.id" v-for="(loc, loInx) in $root.locationProps" :key="'loInx-'+loInx">{{ loc.name }}</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </div> -->

          <div class="col col-filter">
            <b-form-group class="mb-0 custom-f-group-1"
              :label="$t('home.filter.property_type')">
              <b-form-select v-model="$root.searchForm.propType">
                <b-form-select-option :value="null">{{ $t("home.filter.any_option") }}</b-form-select-option>
                <b-form-select-option :value="prop.value" v-for="(prop, prtInx) in $root.propTypeOpts" :key="'prtInx-'+prtInx">{{ $i18n.locale == 'en' ? prop.text  : prop.text_es }}</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </div>

          <div class="col pl-sm-0 col-price">
            <b-form-group class="mb-0 custom-f-group-1"
              :label="$t('search_component.price')">
            </b-form-group>

            <div class="box-range">
              <vue-slider :tooltip="false" ref="slider" :height="2" :interval="50000" :min="0" :max="5000000" v-model="$root.searchForm.price"></vue-slider>

              <div class="range-info">
                <div class="min">
                  <span v-if="minVal($root.searchForm.price[0], 0)">No min</span> <span v-else>Min: $ {{ Intl.NumberFormat("en-US").format($root.searchForm.price[0]) }} <small v-if="$root.currency == 'usd'">USD</small><small v-if="$root.currency == 'mxn'">MXN</small> </span>
                </div>
                <div class="max">
                  <span v-if="maxVal($root.searchForm.price[1], 5000000)">No Max</span> <span v-else>Max: $ {{ Intl.NumberFormat("en-US").format($root.searchForm.price[1]) }} <small v-if="$root.currency == 'usd'">USD</small><small v-if="$root.currency == 'mxn'">MXN</small></span>
                </div>
              </div>
            </div>
          </div>

          <div class="col col-buy-rent">
            <b-form-group class="mb-0 custom-f-group-radio-1"
              v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                v-model="$root.searchForm.buyRent"
                :options="$root.buyRentOpts"
                :aria-describedby="ariaDescribedby"
                button-variant="outline-primary"
                name="radio-btn-outline"
                buttons
                ></b-form-radio-group>
            </b-form-group>
          </div>

          <div class="col col-keywords">
            <div class="w-border">
              <b-form-group class="mb-0 custom-f-group-1 input-text"
                :label="$t('home.filter.search_by')">
                <b-form-input
                  v-model="$root.searchForm.keywords"
                  type="text"
                  :placeholder="$t('home.filter.keywords')"

                ></b-form-input>
              </b-form-group>
            </div>
          </div>

          <div class="col col-button col-btn-search">
            <b-button type="submit" class="btn-search" id="btn-search"><i class="fal fa-search"></i> <span>{{ $t("home.filter.search_btn") }}</span></b-button>
          </div>
          <div class="col col-button col-adv-filters">
            <b-button type="button" class="btn-search" @click="$root.isSearchActive = true"></b-button>
          </div>
        </b-form>
      </div>
    </section>

    <!-- <section class="featured-section">
      <swiper class="swiper" :options="projectsOptions">
        <swiper-slide v-for="(property, fhInx) in propertyoutstanding" :key="'fhInx-'+fhInx">
          <div class="container oversized-container">
            <div class="row">
              <div class="col-lg-6 col-xl-4 col-info">
                <h2 class="sec-title-s1">{{ $t("home.exclusive_section.our_most_exclusive") }}</h2>

                <h5 class="title-sl-1"><span>{{ $t("home.exclusive_section.our_exclusive") }}</span></h5>

                <p>
                  {{ $t("home.exclusive_section.most_breathtaking") }}
                </p>

                <div class="box-buttons" v-if="property != null">
                  <router-link class="btn btn-s1 blue btn-outline" :to="'/property/'+ property.custom_url">{{ $t("home.exclusive_section.btn_viewall") }}</router-link>
                </div>
              </div>

              <div class="col-lg-6 col-view" v-if="(property != null)">
                <router-link class="box" :to="'/property/'+ property.custom_url">
                  <h3 class="title" v-if="$i18n.locale == 'en'">{{property.name}}</h3>
                  <h3 class="title" v-if="$i18n.locale == 'es'">{{property.name_es}}</h3>
                  <div class="box-image">
                    <img src="public/images/pages/home/image-view.png" class="placed-backg" v-bind:style="{ backgroundImage: 'url('+property.imageUrl + ')' }">
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </section> -->
    <!--
    <section class="projects-section" style="padding-top:50px;padding-bottom: 50px;" v-if="projects.length > 0">
      <div class="container oversized-container">
        <div class="col-lg-7 col-xl-5 px-0 mb-1 col-title">
          <h2 class="sec-title-s1">{{ $t("home.current_developments.current_developments") }}</h2>

          <h5 class="title-sl-1"><span>{{ $t("home.current_developments.our_latest") }}</span></h5>

          <p>
            {{ $t("home.current_developments.discover") }}
          </p>
        </div>

        <div class="carousel">
          <swiper class="swiper" :options="projectsOptions">
            <swiper-slide v-for="(project, bhdInx) in projects" :key="'bhdInx-'+bhdInx">
              <div class="box-project">
                <div class="col-lg-6 col-image">
                  <div class="placed-backg image" v-bind:style="{ backgroundImage: 'url('+ project.imageUrl+ ')' }">
                    <img src="public/images/pages/home/project.png">
                  </div>
                </div>

                <div class="col-lg-6 col-info">
                  <h5 class="title">{{ $i18n.locale == 'en' ? project.name  : project.name_es }} </h5>

                  <div class="box-descr" >  {{ $i18n.locale == 'en' ? project.short_description  : project.short_description_es }} </div>

                  <router-link class="btn btn-s1 blue btn-bg" :to="'/projects/'+project.id">{{ $t("home.current_developments.btn_seemore") }}</router-link>
                </div>
              </div>
            </swiper-slide>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </section>-->

    <section class="overflow-hidden places-section" style="padding-top:50px;padding-bottom: 50px;">
      <div class="container oversized-container">
        <div class="col-title">
          <h2 class="sec-title-s1">{{ $t("home.our_locations.our_locations") }}</h2>

          <h5 class="title-sl-1"><span>{{ $t("home.our_locations.learn_more") }}</span></h5>
        </div>

        <div class="row">
          <div class="col-md-6 col-lg-4 col-place-sample-1 xl-4" v-for="(u, uhInx) in destinations" :key="'uhInx-'+uhInx">
            <router-link class="box-link" :to="'/destinations/'+u.id" >
              <div class="box-image">
                <div class="t-550 explore">{{ $t("home.our_locations.btn_explore") }}</div>

                <img src="public/images/pages/properties/location.png">

                <div class="t-250 inside">
                  <div class="placed-backg img-fake"><LazyBackground :src="u.imageUrl"></LazyBackground></div>

                  <!-- <div class="text">
                    <h5 class="t-550">{{ u.name }}</h5>
                  </div> -->
                </div>
              </div>

              <div class="box-text-a">
                <h6 class="search">{{ $t("home.our_locations.search") }}</h6>
                <h5 class="name">{{ $i18n.locale == 'en' ? u.name_en  : u.name }}  </h5>


              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="services-section" v-if="banner_secondary != null">
      <div class="placed-backg content"  v-bind:style="{ backgroundImage: 'url(' + banner_secondary.imageUrl+ ')' }" v-if="banner_secondary.imageUrl != null">
        <div class="container oversized-container">
          <div class="before"></div>
          <div class="after"></div>

          <div class="box">
            <div class="box-title">
              <h2 class="sec-title-s1">{{ $t("home.sell_your_property.sell_your_property") }}<br />{{ $t("home.sell_your_property.sell_your_property_w") }}</h2>

              <!-- <h5 class="title-sl-1"><span>OUR SERVICES</span></h5> -->

              <p>
                {{ $t("home.sell_your_property.let_guide") }}
              </p>
            </div>

            <div class="box-buttons">
              <!-- <router-link class="btn btn-s1 white btn-outline" to="/sell-with-us">SELL WITH US</router-link> -->
              <router-link class="btn btn-s1 blue btn-bg px-4 w-auto" to="/sell-with-us">{{ $t("home.sell_your_property.btn_sell") }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="news-section" style="padding-top:0px !important;padding-bottom: 50px;">
      <div class="container oversized-container">
        <div class="col-title">
          <h2 class="sec-title-s1">{{ $t("home.events.events") }}</h2>

          <h5 class="title-sl-1"><span>{{ $t("home.events.our_latest") }}</span></h5>
        </div>

        <swiper class="swiper" :options="blogOptions">
          <swiper-slide v-for="(a, nhInx) in blogs" :key="'nhInx-'+nhInx">
            <!-- <div class="col-md-6 col-lg-4 col-article-sample-1"> -->
            <div class="col-12 px-0 col-article-sample-1">
              <router-link class="box-link" :to="'/blog/'+$root._converToURL(a.title, a.id)">
                <div class="box-image">
                  <div class="t-550 more">{{ $t("home.events.btn_read_more") }}</div>
                  <div class="placed-backg img-fake" v-if="$i18n.locale == 'en'"><LazyBackground :src="a.imageUrl"></LazyBackground></div>
                  <div class="placed-backg img-fake" v-if="$i18n.locale == 'es'"><LazyBackground :src="a.imageUrl_es"></LazyBackground></div>
                  <img src="public/images/pages/blog/article.png">
                </div>

                <div class="box-text">
                  <h5 class="title">{{ $i18n.locale == 'en' ? a.title  : a.title_es }}</h5>
                  <h6 class="date">{{a.created}}</h6>
                </div>
              </router-link>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </section>

    <!-- <section class="social-section">
      <div class="container oversized-container">
        <div class="box-title">
          <h2 class="sec-title-s1">Social media</h2>

          <h5 class="title-sl-1"><span>OUR DAILY CONECTION</span></h5>
        </div>

        <div class="box-widget">
          <img class="w-100" src="public/images/pages/home/social.jpg">
        </div>
      </div>
    </section> -->

    <section class="placed-backg experience-section"  v-bind:style="{ backgroundImage: 'url(' + banner_bottom.imageUrl+ ')' }" v-if="banner_bottom != null">
      <div class="container oversized-container">
        <div class="row">
          <!-- <div class="col-lg-5 col-logo">
            <img src="public/images/logo-white.png">
            <h5>{{ $t("home.statistics.local_e") }}</h5>
          </div> -->

          <!-- <div class="col-lg col-info">
            <div class="row">
              <div class="col-4 col-count">
                <h3 class="number">3,000</h3>
                <h6 class="text">{{ $t("home.statistics.total_listing") }}</h6>
              </div>

              <div class="col-4 col-count">
                <h3 class="number">$100 M</h3>
                <h6 class="text">{{ $t("home.statistics.sold_volumne") }}</h6>
              </div>

              <div class="col-4 col-count">
                <h3 class="number">1,700</h3>
                <h6 class="text">{{ $t("home.statistics.happy_customer") }}</h6>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </section>

    <section class="container contact-section">
        <div class="col-12 col-title">
          <h2 class="sec-title-s1">{{ $t("home.form_contact.conect_with_us") }}</h2>
        </div>
        <div>
            <div id="hubspot-form"></div>
        </div>
    </section>



    <div class="elfsight-app-98ede52c-ca90-49da-a2f2-e83bc1222d00"></div>
      <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
        <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
        <div v-html="modal.msg"></div>

      </sweet-modal>
  </div>
</template>

<script>
import LazyBackground from '../shared/LazyBackground.vue';
import vueSlider from 'vue-slider-component'; // https://github.com/NightCatSama/vue-slider-component/tree/v2 (para vue 2)
export default {
  components: {
    LazyBackground, vueSlider
  },
  data() {
    return {
      banners: [

      ],
      towns:[],
      locations: [
        //{ title: 'Los cabos' }, { title: 'Rivera Maya' }, { title: 'Monterrey' }, { title: 'Guadalajara' }, { title: 'Agave' },{ title: 'Querétaro' },
      ],

      formContact: {
        name: null,
        lastname: null,
        phone: null,
        email: null,
        msg: null,
        type:'Home',
        purpose:null,
      },

      // == carousels options ==
      autoplayBanners: true,
      bannersOption: {
        loop: true,
        speed:800,
        effect: 'fade',

        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true,
          clickable: true,
        },

        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
      },

      projectsOptions: {
        loop: true,
        speed: 700,
        allowTouchMove: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },

      blogOptions: {
        slidesPerView: 3,
        spaceBetween: 30,

        breakpoints: {
          992: {
            slidesPerView: 3
          },
          768: {
            slidesPerView: 2
          },
          1: {
            slidesPerView: 1
          },
        }
      },
      projects:[],
      blogs:[],
      destinations:[],
      propertyoutstanding:[],
      banner_secondary:{},
      banner_bottom:{},
      recaptchaVerified:false,
      modal:{
        msg:'',
        icon:'',
        block:false,
      },
      // == ==
    }
  },

  methods: {
    pauseBanners() {
      this.autoplayBanners = !this.autoplayBanners;

      if(this.autoplayBanners) {
        this.$refs.mySwiper.$swiper.autoplay.start();
      }else {
        this.$refs.mySwiper.$swiper.autoplay.stop();
      }
    },

    onSubmitSearch(event) {
      event.preventDefault();
      this.$router.push({ name: 'propsPage', query: { buyrent: this.$root.searchForm.buyRent, propType: this.$root.searchForm.propType, keywords: this.$root.searchForm.keywords, minprice: this.$root.searchForm.price[0], maxprice: this.$root.searchForm.price[1]} });
    },

    onSubmitContact(event) {
      event.preventDefault();
      if(this.recaptchaVerified == true){

        this.modal.block = true;
        this.modal.icon = "";
        this.modal.msg = "Loading...";
        this.$refs.modal.open();


        axios.post(tools.url("/api/contact"),this.formContact).then((response)=>{
            // console.log('asda');
            this.modal.block = false;
            this.modal.icon = "success";
            this.modal.msg = "Thank you for contacting us! We will get back to you as soon as possible.";
            this.$refs.recaptcha.reset();
        }).catch((error)=>{
          // console.log('error');
            this.modal.block = false;
            this.modal.icon = "error";
            this.modal.msg = "Error saving information";

        });


      }
      else{
        this.modal.block = false;
          this.modal.icon = "warning";
          this.modal.msg = "Complete the captcha";

      }
    },
    getHome: function(){
      this.$root.modal_loading = true;

        // axios.get('https://sircostarica.com/api/home').then(({data}) =>{
        axios.get(tools.url('/api/home')).then(({data}) =>{
            this.banners = data.banners;
            this.banner_secondary = data.banner_secondary;
            this.banner_bottom = data.banner_bottom;
            this.projects = data.projects;
            this.towns = data.towns;
            this.blogs = data.blogs;
            this.propertyoutstanding = data.propertyoutstanding;
            this.destinations = data.destinations;
            this.$root.modal_loading = false;
        });
    },
    handleSuccess(response) {
      this.recaptchaVerified = true;
    },

    minVal(value, min) {
      if( typeof(value) == 'undefined' || value == min) {
        return true;
      }
    },

    maxVal(value, max) {
      if( typeof(value) == 'undefined' || value == max) {
        return true;
      }
    },
  },
  //insert the following code for vue-meta to work
  metaInfo() {
    return {
      meta: [
        { name: 'og:title', content: this.$root.seoadata.home.meta_title},
        { name: 'og:description', content: this.$root.seoadata.home.meta_description	},
        { name: 'og:keywords', content: this.$root.seoadata.home.meta_keywords},
      ]
    }
  },
  mounted(){
    this.getHome();
    this.$root.searchForm.location = null;
    this.$root.searchForm.propType = null;
    this.$root.searchForm.keywords = null;
    this.$root.searchForm.buyRent = 1;
    this.$root.searchForm.for = null;
    this.$root.searchForm.type = [];
    this.$root.searchForm.price = [];
    this.$root.searchForm.acreage = [];
    this.$root.searchForm.lifestyle = null;
    this.$root.searchForm.destination = null;
    this.$root.searchForm.lifestyle = null;

    if(this.$i18n.locale == 'es'){
      // console.log('asdas');

      setTimeout(function(){
        // console.log('error');

        document.getElementsByClassName('es-widget-title').innerHTML = "Hello World!!";

    }, 6000);

    }
    else{
    }

    if(this.$i18n.locale == 'en' ){
        // Cargar el script de HubSpot
        const script = document.createElement("script");
        script.src = "//js.hsforms.net/forms/embed/v2.js";
        script.charset = "utf-8";
        script.type = "text/javascript";
        script.onload = () => {
            // Crear el formulario después de cargar el script
            if (window.hbspt) {
                window.hbspt.forms.create({
                portalId: "4613600",
                formId: "4fc8f102-568d-40e3-9810-2a919c6e11cc",
                target: "#hubspot-form", // Contenedor donde se renderiza el formulario
                });
            }
        };
        document.body.appendChild(script);
    }
    else{
        // Cargar el script de HubSpot
        const script = document.createElement("script");
        script.src = "//js.hsforms.net/forms/embed/v2.js";
        script.charset = "utf-8";
        script.type = "text/javascript";
        script.onload = () => {
        // Crear el formulario después de cargar el script
        if (window.hbspt) {
            window.hbspt.forms.create({
            portalId: "4613600",
            formId: "082ced8c-31bd-4719-9b37-8468602ab25d",
            target: "#hubspot-form", // Contenedor donde se renderiza el formulario
            });
        }
        };
        document.body.appendChild(script);
    }
  }
}
</script>
