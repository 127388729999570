<template>
  <div :style="backgroundStyle" ref="background" class="lazy-background">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      backgroundImage: ''
    };
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundImage: this.backgroundImage ? `url(${this.backgroundImage})` : 'none'
      };
    }
  },
  watch: {
    src: {
      immediate: true,
      handler() {
        this.updateObserver();
      }
    }
  },
  mounted() {
    this.updateObserver();
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    updateObserver() {
      if (this.observer) {
        this.observer.disconnect();
      }
      this.backgroundImage = '';
      this.$nextTick(() => {
        if ('IntersectionObserver' in window) {
          this.observer = new IntersectionObserver(this.handleIntersect, {
            root: null,
            threshold: 0.1
          });
          this.observer.observe(this.$refs.background);
        } else {
          // Fallback for older browsers
          this.backgroundImage = this.src;
        }
      });
    },
    handleIntersect(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.backgroundImage = this.src;
          this.observer.unobserve(this.$refs.background);
        }
      });
    }
  }
};
</script>

<style scoped>
.lazy-background {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}
</style>