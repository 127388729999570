<template lang="html">
  <div id="agent-page">

    <section class="basic-info-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-8 col-info">
            <h5 class="location">{{agent.address}}</h5>
            <h1 class="name">{{agent.name}}</h1>

            <!-- <h4 class="mb-5 pos">{{agent.profession}}</h4> -->
            <h4 class="mb-5 pos">{{ $t("agents.detail.luxury") }}</h4>

            <a class="t-150 btn-msg" @click="scrollToElement('contactSection')">{{ $t("agents.detail.sendmessage") }} <i class="fal fa-long-arrow-right"></i></a>
          </div>

          <div class="col-lg-4 col-photo"></div>
        </div>
      </div>
    </section>

    <section class="info-section" id="info-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-8 col-text">
            <h6 class="title">{{ $t("agents.detail.aboutme") }}</h6>

            <div class="descr" v-html="agent.description_es" v-if="$i18n.locale == 'es'"></div>
            <div class="descr" v-html="agent.description" v-if="$i18n.locale == 'en'"></div>

          </div>

          <div class="col-lg-4 col-photo">
            <div class="placed-backg photo" v-bind:style="{ backgroundImage: 'url('+ agent.imageUrl +')' }" style="background-position: top;">
              <img src="public/images/pages/agents/agent.png">
            </div>

            <div class="box-contacto">
              <h6 class="mb-2 office">MEXICO SOTHEBY'S INTERNATIONAL REALTY</h6>
              <h6 class="location">{{agent.address}}</h6>

              <hr class="style-1" />

              <h6 class="mb-1 contact">{{ $t("agents.detail.office") }}:  <a style="text-decoration: none;color: #010202;" :href="'tel:'+agent.phone">{{agent.phone}}</a></h6>

              <h6 class="mb-1 contact">{{ $t("agents.detail.mobile") }}: <a style="text-decoration: none;color: #010202;" :href="'tel:'+agent.mobile">{{agent.mobile}}</a></h6>
              <!-- <h6 class="mb-1 contact">Email: {{agent.email}}</h6> -->
              <h6 class="mb-1 contact">{{ $t("agents.detail.email") }}: <a style="text-decoration: none;color: #010202;" :href="'mailto:'+agent.email_office">{{agent.email_office}}</a></h6>
              <h6 class="contact">{{ $t("agents.detail.language") }}: {{agent.languages}}</h6>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container oversized-container properties-section" >
      <div class="row">
        <div class="col-12 col-top">
          <div class="col-title">
            <h5>{{ $t("agents.detail.mylisted") }} ({{this.pagination.total_products }})</h5>
          </div>

          <div class="col col-options" v-if="$i18n.locale == 'en'">
            <b-form-group class="mb-0 custom-f-group-radio-1"
              v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                v-model="buyRent"
                :options="$root.buyRentOpts"
                :aria-describedby="ariaDescribedby"
                button-variant="outline-primary"
                name="radio-btn-outline"
                buttons
                @change="changeBuyRent"
              ></b-form-radio-group>
            </b-form-group>
          </div>

          <div class="col col-options" v-if="$i18n.locale == 'es'">
            <b-form-group class="mb-0 custom-f-group-radio-1"
              v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                v-model="buyRent"
                :options="$root.buyRentOptsEs"
                :aria-describedby="ariaDescribedby"
                button-variant="outline-primary"
                name="radio-btn-outline"
                buttons
                @change="changeBuyRent"
              ></b-form-radio-group>
            </b-form-group>
          </div>
          <div class="col col-filter" style="color: #002349;font-size: .95rem;padding: 6px 0;text-align: right">
              <span>{{ $t("agents.filterby") }}</span>
              <b-form-select v-model="formSearch.order" style="background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23002349' d='M2 0 0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E);border: 0;border-radius: 0;color: #002349;cursor: pointer;font-size: .85rem;font-weight: 700;height: inherit;padding-bottom: 0;padding-left: 5px;padding-top: 0;width: 155px;">
                  <b-form-select-option value="1">{{ $t("result.filter_pricelow") }}</b-form-select-option>
                  <b-form-select-option value="2">{{ $t("result.filter_pricehigh") }}</b-form-select-option>
              </b-form-select>
          </div>

              <!-- <div class="col col-filter" style="color: #002349;font-size: .95rem;padding: 6px 0;text-align: right">
                <span>{{ $t("agents.filterby") }}</span>
                <b-form-select v-model="formSearch.order" style="background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23002349' d='M2 0 0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E);border: 0;border-radius: 0;color: #002349;cursor: pointer;font-size: .85rem;font-weight: 700;height: inherit;padding-bottom: 0;padding-left: 5px;padding-top: 0;width: 80px;">
                  <b-form-select-option value="asc">A {{ $t("agents.filterorder") }} Z</b-form-select-option>
                  <b-form-select-option value="desc">Z {{ $t("agents.filterorder") }} A</b-form-select-option>
                </b-form-select>
              </div> -->


        </div>
      </div>

      <div class="row row-props-xl-3" v-if="properties.length > 0" >
        <div class="col-md-6 col-lg-4 col-xl-3 col-property-sample-1" v-for="(prop, prInx) in properties" :key="'prInx-'+prInx" >
          <router-link class="box-link" :to="'/property/'+prop.custom_url" target="_blank">
            <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+prop.imageUrl+')' }">
              <img src="public/images/pages/properties/property.png">
            </div>

            <div class="box-text">
              <h5 class="name" v-if="$i18n.locale == 'en'">{{ prop.name }}<br><br>{{ prop.state }}, {{ prop.city }}</h5>
                    <h5 class="name" v-if="$i18n.locale == 'es'">{{ prop.name_es }}<br><br>{{ prop.state }}, {{ prop.city }}</h5>
              <div v-if="prop.price_request == 0">

                      <!-- <div v-if="$root.currency == 'usd'">
                          <h6 class="price" v-if="prop.price_usd > 0"> $ {{ formatNum(prop.price_usd) }} USD</h6>
                          <h6 class="price" v-else> $ {{ formatNum(prop.price_mxn) }} MXN</h6>
                      </div>
                      <div v-if="$root.currency == 'mxn'">
                        <h6 class="price" v-if="prop.price_mxn > 0"> $ {{ formatNum(prop.price_mxn) }} MXN</h6>
                          <h6 class="price" v-else> $ {{ formatNum(prop.price_usd) }} USD</h6>
                      </div> -->
                        <h6 class="price" v-if="prop.price_usd > 0"> $ {{ formatNum(prop.price_usd) }} USD</h6>
                        <h6 class="price" v-else>-</h6>
                        <!-- <h6 class="price" v-if="prop.price > 0"> $ {{ formatNum(prop.price) }} MXN</h6>
                        <h6 class="price" v-else>-</h6> -->

                    </div>
                    <div v-else>
                      <h6 class="price" v-if="$i18n.locale == 'es'">Precio bajo solicitud</h6>
                      <h6 class="price" v-if="$i18n.locale == 'en'">Price upon request</h6>
                    </div>

                    <div class="box-prop-info">
                      <span v-if="prop.bedrooms > 0" style="padding-right:0px">{{ prop.bedrooms}} {{ $t("result.result_bed") }} , </span>
                    <span style="padding-right: 0px;padding-left:0px" v-if="prop.toilets > 0">{{ prop.toilets}} {{ $t("result.result_bath") }}</span>
                    <span style="padding-left: 0px;" v-if="prop.toilets_partial != null && prop.toilets_partial != 0">| {{prop.toilets_partial}} {{ $t("result.result_bath_partial") }}</span>

                    
                  </div>
            </div>
          </router-link>
        </div>





      </div>
      <div class="row row-props-xl-3" v-else>
          <p style="padding-left: 15px;">{{ $t("agents.detail.notpropeties") }} </p>

      </div>
      <br v-if="properties.length > 0"><br v-if="properties.length > 0">
      <div class="row" v-if="properties.length > 0">
            <div class="col-12 text-center">
              <div class="d-inline-block mx-0 col-pagination-sample-1">
                <b-pagination-nav :link-gen="linkGen" :number-of-pages="pagination.total" use-router></b-pagination-nav>
              </div>
            </div>
        </div>
    </section>

    <section class="container oversized-container contact-section">
      <div class="anchor" ref="contactSection"></div>

      <b-form class="row justify-content-center" @submit="onSubmit">
        <div class="col-12 col-title">
          <h2 class="title">{{ $t("agents.detail.contactme") }}</h2>
        </div>

        <div class="col-lg-4 mb-2">
          <b-form-group class="custom-f-group-2"
            :label='$t("agents.form.name")'>
            <b-form-input
              v-model="form.name"
              type="text"
              size="lg"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-lg-4 mb-2">
          <b-form-group class="custom-f-group-2"
          :label='$t("agents.form.lastname")'>
            <b-form-input
              v-model="form.lastname"
              type="text"
              size="lg"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-12"></div>

        <div class="col-lg-4 mb-2">
          <b-form-group class="custom-f-group-2"
          :label='$t("agents.form.phone")'>
            <b-form-input
              v-model="form.phone"
              type="number"
              size="lg"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-lg-4 mb-2">
          <b-form-group class="custom-f-group-2"
          :label='$t("agents.form.email")'>
            <b-form-input
              v-model="form.email"
              type="email"
              size="lg"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-12"></div>

        <div class="col-lg-8 mb-2">
          <b-form-group class="custom-f-group-2"
          :label='$t("agents.form.comentarios")'>
            <b-form-textarea
              v-model="form.msg"
              placeholder=""
              rows="3"
              max-rows="3"
              required
            ></b-form-textarea>
          </b-form-group>
        </div>

        <div class="col-5" style="background-color: black;"></div>
        <div class="col-8 col-captcha">

          <vue-recaptcha
            sitekey="6LcaE4okAAAAAFYKGFfWc9wbF0IXRcRBzSzZmiyw"
            ref="recaptcha"
            @verify="handleSuccess"
            >

          </vue-recaptcha>
        </div>

        <div class="col-12 mt-3 text-center">
          <b-button type="submit" class="btn-s1 blue btn-bg">{{ $t("agents.form.submit") }}</b-button>
        </div>
      </b-form>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      properties: [
        /*{ id: 1, imageUrl: 'public/images/pages/properties/property-1.jpg', name: 'Margarita valley', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },
        { id: 1, imageUrl: 'public/images/pages/properties/property-2.jpg', name: 'Villa de los Angeles', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },
        { id: 1, imageUrl: 'public/images/pages/properties/property-3.jpg', name: 'Vista hermosa', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },
        { id: 1, imageUrl: 'public/images/pages/properties/property-4.jpg', name: 'Villa Angeles', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },

        { id: 1, imageUrl: 'public/images/pages/properties/property-4.jpg', name: 'Villa Angeles', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },
        { id: 1, imageUrl: 'public/images/pages/properties/property-3.jpg', name: 'Vista hermosa', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },
        { id: 1, imageUrl: 'public/images/pages/properties/property-2.jpg', name: 'Villa de los Angeles', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },
        { id: 1, imageUrl: 'public/images/pages/properties/property-1.jpg', name: 'Margarita valley', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },
        */
      ],
      propertiesAll:{

      },

      buyRent: 1,

      form: {
        name: null,
        lastname: null,
        phone: null,
        email: null,
        comments: null,
        agents_id:null
      },
      agent:{
        properties:[]
      },
      pagination:{
        currentpage:1,
        total:1,
        number: 10,
        total_products:1,
      },
      recaptchaVerified:false,
      modal:{
        msg:'',
        icon:'',
        block:false,
      },
      formSearch:{
        order:'2'
      }

    }
  },
  watch:{
    /*'buyRent':function(val){
      console.log('1');
      this.pagination.currentpage = 1;
      this.$router.push({ path: '/agents/'+this.$route.params.id, query: { order: this.formSearch.order,type:this.buyRent,page:this.pagination.currentpage } });

    },*/
    '$route' (to, from){
      if(this.$route.query.page){
        this.pagination.currentpage = this.$route.query.page;
      }
      else{
          this.pagination.currentpage = 1;
      }
      this.getAgentProperties();
    },
    'formSearch.order':function(val){
      this.$router.push({ path: '/agents/'+this.$route.params.id, query: { order: this.formSearch.order,type:this.buyRent,page:this.pagination.currentpage } });

        //this.getAgentProperties();
    }
  },
  methods: {
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      })
    },

    scrollToElement(ref) {
      this.$refs[ref].scrollIntoView({ behavior: "smooth" })
    },

    formatNum(num) {
      let x = Intl.NumberFormat("en-US").format(num);
      return x;
    },

    onSubmit(evt) {
      evt.preventDefault();

      if(this.recaptchaVerified == true){
          this.form.agents_id = this.$route.params.id;
          this.form.type = 'Contacto a agente';
          var params = this.form;
          var apiURL = tools.url("/api/contactagents");

          axios.post( apiURL,params )
          .then( (response) => {
            for (let prop in this.form) {
              this.form[prop] = '';
            }
            this.$router.push('/thankyou-page');

          })
          .catch( (error) => {
            // console.log(error.response.data);
            let errors = error.response.data;

            for (let prop in errors) {
              // console.log(errors[prop]);
              this.makeToast('danger', errors[prop], '¡Error!');
            }
          });
      }
      else{
        this.modal.block = false;
          this.modal.icon = "warning";
          this.modal.msg = "Complete the captcha";
          this.$refs.modal.open();
      }
    },
    getAgent() { // Obtener la info de la BD
      this.$root.modal_loading = true;
      // axios.get("https://sircostarica.com/api/agents/"+this.$route.params.id).then((response)=>{
      axios.get(tools.url("/api/agents/"+this.$route.params.id)).then((response)=>{
        this.agent = response.data;
        this.$root.modal_loading = false;
      });
    },
    linkGen(pageNum) {
      return {
          path: '/agents/'+this.$route.params.id,
          query: {
            order:this.formSearch.order,
            type:this.buyRent,
            page: pageNum }
      }
    },
    getAgentProperties() {
      this.$root.modal_loading = true;
      var data = {
        'id':this.$route.params.id,
        'type':this.buyRent,
        'order':this.formSearch.order

      };
      axios.post(tools.url("/api/agentsproperties?page=" + this.pagination.currentpage),data).then((response)=>{

        this.propertiesAll = response.data.data;
        this.pagination.total = response.data.last_page;
        this.pagination.total_products = response.data.total;

        this.properties = response.data.data;
        this.$root.modal_loading = false;
      });
    },
    handleSuccess(response) {
      this.recaptchaVerified = true;

    },
    changeBuyRent(){
      this.pagination.currentpage = 1;
      this.$router.push({ path: '/agents/'+this.$route.params.id, query: { order: this.formSearch.order,type:this.buyRent,page:this.pagination.currentpage } });

    }
  },
  //insert the following code for vue-meta to work
  metaInfo() {
    return {
      meta: [
        { name: 'og:title', content: this.agent.meta_title},
        { name: 'og:description', content: this.agent.meta_description	},
        { name: 'og:keywords', content: this.agent.meta_keywords},
      ]
    }
  },
  mounted(){
    var hasfilter = false;
    if(this.$route.query.page){
      this.pagination.currentpage = this.$route.query.page;
      hasfilter = true;
    }
    if(this.$route.query.type){
      this.buyRent = this.$route.query.type;
      hasfilter = true;
    }
    if(this.$route.query.order){
      this.formSearch.order = this.$route.query.order;
      hasfilter = true;
    }
    if(this.$route.params.id){
        this.getAgent();

    }


       this.getAgentProperties();

  },
}

</script>
